<template>
  <form class="calc">
    <div class="container">
      <h2 class="prg__tabs__title">Калькулятор «Лизинг-Трейд»</h2>
      <div class="calc__body">
        <div class="calc__form">
          <div class="calc__types" v-if="vehiclesSorted.length">
            <label
              class="calc__types__field"
              :class="{ active: activeType === vehicle.id }"
              v-for="(vehicle, i) in vehiclesSorted"
              :key="i"
            >
              <input type="radio" v-model="activeType" :value="vehicle.id" />
              <i class="icon" v-html="vehicle.icon"></i>
              <span class="calc__types__name">{{ vehicle.button_title || vehicle.title }}</span>
            </label>
          </div>
          <div class="calc__inputs">
            <div class="calc__input">
              <label>Стоимость предмета лизинга</label>
              <money v-model="calc.leasingPrice" v-bind="money"></money>
            </div>
            <div class="calc__input">
              <label for="contrib">Первоначальный взнос, %</label>
              <input
                type="number"
                id="contrib"
                min="1"
                max="49.9"
                step="0.1"
                placeholder=""
                v-model="calc.firstPay"
                @input="handleUpdateContrib"
                @blur="handleUpdateMobileContrib"
              />
            </div>
          </div>
          <div class="calc__inputs">
            <div class="calc__input">
              <label for="duration">Срок лизинга (От 12 до 84 мес)</label>
              <input
                type="number"
                id="duration"
                min="12"
                max="84"
                step="1"
                placeholder=""
                v-model="calc.duration"
                @input="handleUpdateDuration"
                @blur="handleUpdateMobileDuration"
              />
            </div>
            <!--            <div class="calc__input" v-if="program && program.options && program.options.length">-->
            <!--              <label for="option">Опция</label>-->
            <!--              <select id="option">-->
            <!--                <option :value="prg.id" :key="i" v-for="(prg, i) in program.options">{{ prg.title }}</option>-->
            <!--              </select>-->
            <!--            </div>-->
          </div>
          <span class="calc__note"
            >Расчет является примерным и не включает страховые взносы. Представленные сведения носят
            исключительно информационный характер и ни при каких условиях не являются публичной офертой.
            Окончательные условия каждого договора определяются в индивидуальном порядке. Для получения
            конечной цены вы можете заполнить форму заявки.
          </span>
        </div>
        <div class="calc__results">
          <div class="calc__results__white">
            <span class="calc__results__title">Результаты расчета:</span>
            <span class="calc__results__price"
              >{{ perMonth.toLocaleString("ru-RU", { maximumFractionDigits: 0 }) }} ₽</span
            >
            <span class="calc__results__type">Ежемесячный платеж</span>
          </div>
          <div class="calc__results__dark">
            <div class="calc__results__item">
              <div class="calc__dark__price">
                {{ summ.toLocaleString("ru-RU", { maximumFractionDigits: 0 }) }} ₽
              </div>
              <div class="calc__dark__type">Сумма договора</div>
            </div>
            <div class="calc__results__item">
              <div class="calc__dark__price">
                {{ tax.toLocaleString("ru-RU", { maximumFractionDigits: 0 }) }} ₽
              </div>
              <div class="calc__dark__type">Экономия на налогах</div>
            </div>
          </div>
          <div class="calc__results__btn 123">
            <router-link :to="{ name: 'request' }" class="btn">Отправить заявку</router-link>
          </div>
          <span class="calc__results__note"
            >Сезонным бизнесам — сезонные графики. Подробности у менеджера.</span
          >
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { Money } from "v-money";

export default {
  name: "CalculatorComponent",
  data() {
    return {
      debounceContribInput: null,
      debounceDurationInput: null,
      debounceTimeout: null,
      activeType: null,
      perMonth: 0,
      summ: 0,
      tax: 0,
      money: {
        decimal: ".",
        thousands: " ",
        prefix: "",
        suffix: " ₽",
        precision: 0,
        masked: false,
      },
      calc: {
        leasingPrice: this.price,
        firstPay: 49,
        duration: 60,
      },
      minFirstPayPercent: 49,
      maxFirstPayPercent: 49.5,
    };
  },
  props: {
    vehicles: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    activeVehicle: {
      type: Object,
    },
    price: {
      type: Number,
      default: 2000000,
    },
  },
  computed: {
    program() {
      if (this.$store.state.program_page && this.$store.state.program_page.program) {
        return this.$store.state.program_page.program;
      } else {
        return undefined;
      }
    },
    conditionsRaw() {
      if (this.program && this.program.conditions_array.length) {
        return this.program.conditions_array;
      } else {
        return [];
      }
    },
    vehiclesSorted() {
      if (this.vehicles) {
        const vehicles = this.vehicles;
        return vehicles.sort((a, b) => {
          return (a.position || 0) - (b.position || 0);
        });
      } else {
        return [];
      }
    },
  },
  watch: {
    "calc.leasingPrice"() {
      this.debounceGetCalc();
    },
    activeType() {
      this.debounceGetCalc();
    },
  },
  methods: {
    debounceGetCalc() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.getCalc();
      }, 300);
    },
    getPropType(id) {
      switch (id) {
        case 1:
          return "light";
        case 2:
          return "commercial";
        case 3:
          return "special";
        case 4:
          return "equipment";
        case 6:
          return "equipment";
      }
    },
    getCalc() {
      const data = {
        query:
          "query ($price:Float!, $prepaid:Float!, $duration:Int!, $propType:PropertyTypes) {tryCalc(input:{price: $price,prepaid: $prepaid,duration: $duration,propType: $propType})}",
        variables: {
          price: parseFloat(this.calc.leasingPrice),
          prepaid: (parseFloat(this.calc.firstPay) / 100) * parseFloat(this.calc.leasingPrice),
          duration: parseInt(this.calc.duration),
          propType: this.getPropType(this.activeType),
        },
      };
      this.$store.dispatch("GET_CALC", data).then(({ data }) => {
        const d = data.data;
        if (d.tryCalc) {
          this.tax = d.tryCalc.economy;
          this.summ = d.tryCalc.summ;
          this.summ = d.tryCalc.summ;
          if (d.tryCalc.graph && d.tryCalc.graph.length > 1) {
            this.perMonth = d.tryCalc.graph[1].plan;
          }
        }
      });
    },
    handleUpdateContrib(event) {
      if (window.innerWidth > 1024) {
        clearTimeout(this.debounceContribInput);
        this.debounceContribInput = setTimeout(() => {
          if (parseFloat(event.target.value) < parseFloat(event.target.min)) {
            this.calc.firstPay = parseFloat(event.target.min);
          }
          if (parseFloat(event.target.value) > parseFloat(event.target.max)) {
            this.calc.firstPay = parseFloat(event.target.max);
          }
          this.debounceGetCalc();
        }, 1000);
      }
    },
    handleUpdateMobileContrib(event) {
      if (parseFloat(event.target.value) < parseFloat(event.target.min)) {
        this.calc.firstPay = parseFloat(event.target.min);
      }
      if (parseFloat(event.target.value) > parseFloat(event.target.max)) {
        this.calc.firstPay = parseFloat(event.target.max);
      }
      this.debounceGetCalc();
    },
    handleUpdateDuration(event) {
      if (window.innerWidth > 1024) {
        clearTimeout(this.debounceDurationInput);
        this.debounceDurationInput = setTimeout(() => {
          if (parseInt(event.target.value) < parseInt(event.target.min)) {
            this.calc.duration = parseInt(event.target.min);
          }
          if (parseInt(event.target.value) > parseInt(event.target.max)) {
            this.calc.duration = parseInt(event.target.max);
          }
          this.debounceGetCalc();
        }, 1000);
      }
    },
    handleUpdateMobileDuration(event) {
      if (parseInt(event.target.value) < parseInt(event.target.min)) {
        this.calc.duration = parseInt(event.target.min);
      }
      if (parseInt(event.target.value) > parseInt(event.target.max)) {
        this.calc.duration = parseInt(event.target.max);
      }
      this.debounceGetCalc();
    },
  },
  mounted() {
    if (this.vehiclesSorted.length) {
      let vehicles = this.vehiclesSorted;
      if (this.conditionsRaw.length) {
        vehicles = this.vehiclesSorted.filter(
          (vehicle) => this.conditionsRaw.filter((c) => c.vehicle_id === vehicle.id).length
        );
      }
      if (vehicles.length) {
        this.activeType =
          this.activeVehicle && this.activeVehicle.id ? this.activeVehicle.id : vehicles[0].id;
      }
    }
    this.getCalc();
  },
  components: {
    Money,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"
.calc {
  display flex
  background-image: url("/public/images/calc_back2.png"), url("/public/images/calc_back.png");
  background-position: right top, right;
  background-repeat: no-repeat, no-repeat;
  margin-bottom 50px
  +below(860px) {
    background none
  }
  +below(768px) {
    margin-bottom 30px
  }

  .container {
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    width 100%
  }

  &__body {
    display flex
    align-items stretch
    justify-content flex-start
    width 100%
    +below(860px) {
      flex-direction column
      justify-content stretch
    }
  }

  &__form {
    display flex
    flex-direction column
    align-items stretch
    justify-content flex-start
    background: main_dark;
    border-radius: radius;
    padding 30px
    box-sizing border-box
    max-width 770px
    width 100%
    +below(860px) {
      max-width 100%
    }

    .calc__note {
      color main_white
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      margin-top auto
      max-width 100%
      width 100%
    }
  }

  &__results {
    background: main_gray;
    border-radius: radius;
    padding 15px
    display flex
    flex-direction column
    align-items center
    justify-content center
    max-width 290px
    width 100%
    +below(860px) {
      max-width 100%
    }

    &__title {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
    }

    &__price {
      font-weight: normal;
      font-size: 32px
      line-height: 38px
      text-align: center;
      margin 10px 0
    }

    &__type {
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
    }

    &__white {
      display flex
      flex-direction column
      align-items center
      justify-content center
      text-align center
      background main_white
      border-radius 5px
      padding 15px 15px 21px
      width 100%
      color main_black
    }

    &__dark {
      display flex
      flex-direction column
      padding 0 12px
      width 100%

      &__price {
        font-weight: normal;
        font-size: 22px;
        line-height: 27px;
        text-align: center;
        margin-bottom 5px
      }

      &__type {
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
      }
    }

    &__item {
      display flex
      flex-direction column
      text-align center
      justify-content center
      padding 15px 15px 20px
      background main_dark
      color main_white

      &:not(:last-child) {
        border-bottom 1px solid rgba(main_white, 0.1)
      }

      &:last-child {
        border-radius: 0 0 5px 5px;
        padding-bottom 40px
      }
    }

    &__btn {
      display flex
      justify-content center
      text-align center
      width 100%
      transform translateY(-50%)

      button {
        padding-left 22px
        padding-right 22px
      }
    }

    &__note {
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      color: main_black;
      width 100%
    }
  }

  &__types {
    display flex
    align-items center
    margin -12px -12px 22px
    overflow hidden
    overflow-x auto
    position relative
    padding-bottom 15px

    &__name {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      color: main_white;
      margin-top 14px
      transition .3s
    }

    &__field {
      position relative
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin 12px
      flex-shrink: 0;
      cursor pointer
      flex-basis auto
      width auto
      min-width 100px
      max-width none

      &.active {
        .icon svg path {
          fill main_color
        }

        .calc__types__name {
          color main_color
        }
      }

      &.disabled {
        .icon svg path {
          fill rgba(main_white, 0.5)
        }

        .calc__types__name {
          color rgba(main_white, 0.5)
        }
      }

      input {
        opacity 0
        visibility hidden
        absolute left top bottom right
      }

      .icon {
        width 80px
        height 60px
        display flex
        align-items flex-end
        justify-content center

        svg {
          width 100%
          height 100%

          path {
            transition .3s
            fill main_white
          }
        }
      }

      .equipment-icon {
        width 60px
        height 60px
      }

    }
  }

  &__inputs {
    display flex
    align-items: flex-end;
    justify-content flex-start
    margin -10px -10px 10px
    +below(860px) {
      flex-direction column
      align-items stretch
      justify-content stretch
    }
  }

  &__input {
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    flex-grow 1
    margin 10px

    label {
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: main_white;
      margin-bottom 13px
    }

    input
    select {
      background: rgba(main_white, 0.5);
      border: 1px solid #828d96;
      box-sizing: border-box;
      border-radius: radius;
      padding 15px 16px
      width 100%
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: main_white;

      option {
        background: main_dark;
        color: main_white
      }

      &::placeholder {
        color: rgba(main_white, .5);
      }
    }
  }

  &__note {
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: main_black;
    margin-top 15px
    max-width 770px
    width 100%
  }
}
</style>
