<template>
  <img
    :src="src || head_img | image_by_id($store.state, { width, height, quality })"
    :alt="head_img ? head_img.alt : defaultAlt"
    loading="lazy"
    class="img"
    decoding="async"
    :class="{ lazy: loader, loaded }"
    @load="loaded = true"
    @error="loaded = true"
  />
</template>

<script>
export default {
  name: "ImgComponent",
  props: {
    src: String,
    head_img: Object,
    defaultAlt: {
      type: String,
      default() {
        return "";
      },
    },
    quality: {
      type: String,
      default: "99",
    },
    width: [Number, String],
    height: [Number, String],
    strategy: {
      type: [Number, String],
      default() {
        return 2;
      },
    },
    loader: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loaded: false,
    };
  },
};
</script>

<style lang="stylus">
.img {
  object-fit cover
  object-position center
}

.lazy {
  opacity 0
  transition var(--transition)

  &.loaded {
    opacity 1
  }
}
</style>
