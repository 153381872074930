var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.vehicle)?_c('section',{staticClass:"section brands"},[(_vm.title)?_c('h2',{staticClass:"prg__tabs__title brands__title"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('div',{ref:"brands",staticClass:"brands__list"},_vm._l((_vm.brands),function(brand,i){return _c('router-link',{key:i,staticClass:"brands__item",attrs:{"to":{
        name: 'brand-page',
        params: {
          vehicle: _vm.vehicle,
          brand: brand.link,
        },
      }}},[(brand.head_img)?_c('ImgComponent',{staticClass:"brands__image",attrs:{"defaultAlt":brand.title,"head_img":brand.head_img.img,"width":"250","height":"250","quality":"100"}}):_vm._e(),_c('h3',{staticClass:"prg__conditions__name brands__name"},[_vm._v(_vm._s(brand.title))])],1)}),1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }