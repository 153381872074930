<template>
  <main class="main services-open-page">
    <div class="container" v-if="vehicle">
      <BreadcrumbsComponent :title="pageTitle" />
      <section class="prg">
        <div class="prg__inner-fix">
          <div class="prg__left">
            <div class="prg__info">
              <EditorJSComponent :text="JSON.parse(vehicle.text)" />
            </div>
          </div>
          <div class="prg__right" v-if="vehicle.slider_img">
            <img
              loading="lazy"
              decoding="async"
              :src="$store.state.api + vehicle.slider_img.img.url"
              :alt="vehicle.slider_img.img.alt"
            />
          </div>
        </div>
      </section>
      <h2 class="prg__tabs__title" v-if="programs && programs.length">Доступные программы</h2>
      <div class="inv__tabs" v-if="programs && programs.length">
        <div class="inv__tabs__list" :class="{ 'flickity-ready': flickityReady }">
          <div
            :class="{ active: activeTab === index }"
            @click="activeTab = index"
            :key="index"
            class="inv__tabs__list-item"
            v-for="(prg, index) in programs"
          >
            <span>{{ prg.title }}</span>
          </div>
        </div>
        <div class="inv__tabs__content">
          <EditorJSComponent :text="JSON.parse(programs[activeTab].text)" />
          <template v-if="options.length">
            <h3 class="inv__tabs__title">Доступные опции</h3>
            <div class="prg__tabs__buttons">
              <template v-for="(option, index) in options">
                <router-link
                  :to="{ name: 'option', params: { url: option.link } }"
                  :key="index"
                  v-if="option.tooltip"
                  v-tippy="{ followCursor: false, placement: 'top' }"
                  :content="option.tooltip"
                  class="btn-dark"
                >
                  {{ option.title }}
                </router-link>
                <router-link
                  :to="{ name: 'option', params: { url: option.link } }"
                  :key="index"
                  v-else
                  class="btn-dark"
                >
                  {{ option.title }}
                </router-link>
              </template>
            </div>
          </template>
          <template v-if="conditions.length">
            <h3 class="inv__tabs__title">Условия компании «Лизинг-Трейд»</h3>
            <ul class="prg__conditions__list">
              <li v-for="(condition, index) in conditions" :key="index">
                <div class="prg__conditions__content">
                  <span class="prg__conditions__date" v-if="condition.from">{{ condition.from }}</span>
                  <span class="prg__conditions__number" v-if="condition.value">{{ condition.value }}</span>
                  <span class="prg__conditions__modifier" v-if="condition.mod">{{ condition.mod }}</span>
                  <h3 class="prg__conditions__name">{{ condition.title }}</h3>
                </div>
                <h3 class="prg__conditions__name">{{ condition.title }}</h3>
              </li>
            </ul>
          </template>
          <router-link :to="{ name: 'program', params: { url: programs[activeTab].link } }" class="btn"
            >Подробнее о программе
          </router-link>
        </div>
      </div>
      <BrandsSlider :brands="brands" v-if="brands.length" :vehicle="$route.params.url" :title="pageTitle" />
    </div>
    <CalculatorComponent :vehicles="$store.state.vehicle_page.vehicles" v-if="$store.state.vehicle_page" />
    <section class="container" v-if="vehicle">
      <div
        class="about prg__about_block"
        v-if="
          vehicle.description &&
          JSON.parse(vehicle.description).blocks &&
          JSON.parse(vehicle.description).blocks.length
        "
      >
        <EditorJSComponent :text="JSON.parse(vehicle.description)" />
      </div>
      <h2 class="prg__tabs__title" v-if="advantages.length">Преимущества «Лизинг-Трейд»</h2>
      <div class="prg__advantages type_benefits" v-if="advantages.length">
        <div class="prg__advantages-item" v-for="(advantage, index) in advantages" :key="index">
          <i class="icon" v-html="advantage.icon"></i>
          <div class="prg__advantages-content">
            <div class="prg__advantages-title">{{ advantage.title }}</div>
            <div class="prg__advantages-text">
              <EditorJSComponent :text="getAdvantagesDescription(advantage)" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <ProgramRequest />
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";
import ProgramRequest from "@/views/vehicles/components/Request.vue";
import CalculatorComponent from "components/Calculator.vue";
import BrandsSlider from "components/BrandsSlider.vue";
import { cityIn } from "lvovich";

export default {
  name: "VehiclePage",
  async asyncData({ store, route, link }) {
    store.state.alternateLink = link;
    await store.dispatch("GET_VEHICLE_PAGE", route.params.url);
  },
  data() {
    return {
      activeTab: 0,
      flickityReady: false,
      flickity: undefined,
    };
  },
  computed: {
    brands() {
      if (this.vehicle && this.vehicle.brands) {
        return this.vehicle.brands;
      } else {
        return [];
      }
    },
    conditions() {
      if (this.programs && this.programs[this.activeTab] && this.programs[this.activeTab].conditions_array) {
        return this.programs[this.activeTab].conditions_array.filter(
          (c) => c.vehicle_id === parseInt(this.vehicle.id)
        );
      }
      return [];
    },
    programs() {
      if (
        this.$store.state.vehicle_page &&
        this.$store.state.vehicle_page.vehicle &&
        this.$store.state.vehicle_page.vehicle.programs.length
      ) {
        const programsStringified = this.$store.state.vehicle_page.vehicle.programs.map((v) =>
          JSON.stringify(v)
        );
        const programs = JSON.parse(JSON.stringify(this.$store.state.vehicle_page.vehicle.programs));
        return programs
          .filter((v, i) => programsStringified.indexOf(JSON.stringify(v)) === i)
          .sort((a, b) => {
            return (a.position || 0) - (b.position || 0);
          });
      } else {
        return undefined;
      }
    },
    pageTitle() {
      let page = {};
      let city = this.$store.state.cities.find((c) => c.title_eng === this.$store.state.geoData.city);
      city = cityIn(city ? city.title : "Казань");
      if (this.$store.state.vehicle_page && this.$store.state.vehicle_page.vehicle) {
        page = this.$store.state.vehicle_page.vehicle;
      }
      return (page.title || page.meta_title || "").replace(/{{ city }}/g, `в ${city}`);
    },
    vehicle() {
      if (this.$store.state.vehicle_page && this.$store.state.vehicle_page.vehicle) {
        return this.$store.state.vehicle_page.vehicle;
      } else {
        return undefined;
      }
    },
    about() {
      if (this.$store.state.contacts) {
        return this.$store.state.contacts.about;
      } else {
        return undefined;
      }
    },
    options() {
      if (
        this.programs &&
        this.activeTab !== null &&
        this.programs[this.activeTab] &&
        this.programs[this.activeTab].options
      ) {
        return this.programs[this.activeTab].options.filter((opt) => {
          return opt.conditions_array.filter((cnd) => cnd.vehicle_id === this.vehicle.id).length;
        });
      } else {
        return [];
      }
    },
    advantages() {
      if (this.vehicle && this.vehicle.advantages) {
        return this.vehicle.advantages;
      } else {
        return [];
      }
    },
  },
  mounted() {
    if (document.getElementById("flickity_tabs")) {
      const Flickity = require("flickity");
      this.flickity = new Flickity("#flickity_tabs", {
        prevNextButtons: false,
        pageDots: false,
        cellAlign: "left",
        contain: true,
        on: {
          ready: () => {
            this.flickityReady = true;
          },
        },
      });
    }
  },
  methods: {
    getAdvantagesDescription(adv) {
      if (adv && adv.description) {
        let haveError = false;
        try {
          JSON.parse(adv.description);
        } catch (e) {
          haveError = true;
        }
        if (!haveError) {
          return JSON.parse(adv.description);
        } else {
          return {
            blocks: [
              {
                type: "paragraph",
                data: { text: adv.description },
              },
            ],
          };
        }
      }
      return {
        blocks: [],
      };
    },
  },
  metaInfo() {
    let api = this.$store.state.api;
    let metaFullPath = this.$route.fullPath;
    let alternateLink = this.$store.state.alternateLink;
    let data = {};
    let city = this.$store.state.cities.find((c) => c.title_eng === this.$store.state.geoData.city);
    city = cityIn(city ? city.title : "Казань");
    if (this.$store.state.vehicle_page && this.$store.state.vehicle_page.vehicle) {
      data = this.$store.state.vehicle_page.vehicle;
    }
    const title = (data.meta_title || data.title || "").replace(/{{ city }}/g, `в ${city}`);

    let parentMeta = this.$route.meta.parent.map((item, i) => {
      return {
        "@type": "ListItem",
        position: i + 2,
        name: item.title,
        item:
          api +
          "/" +
          this.$router.resolve({
            name: item.name,
            params: item.params,
          }).href,
      };
    });

    return {
      title,
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: title,
            description: (data.meta_description || "").replace(/{{ city }}/g, `в ${city}`),
          },
        },
        {
          type: "application/ld+json",
          json: {
            "@context": "http://www.schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: "1",
                name: "Главная",
                item: api,
              },
              ...parentMeta,
              {
                "@type": "ListItem",
                position: "3",
                name: data.title,
                item: api + metaFullPath,
              },
            ],
          },
        },
      ],
      link: [
        { rel: "canonical", href: alternateLink },
        { rel: "alternate", hreflang: "x-default", href: alternateLink },
      ],
      meta: [
        {
          vmid: "keywords",
          name: "keywords",
          content: (data.meta_keywords || "").replace(/{{ city }}/g, `в ${city}`),
        },
        {
          vmid: "description",
          name: "description",
          content: (data.meta_description || "").replace(/{{ city }}/g, `в ${city}`),
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: title,
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: (data.meta_description || "").replace(/{{ city }}/g, `в ${city}`),
        },
      ],
    };
  },

  components: {
    BrandsSlider,
    CalculatorComponent,
    ProgramRequest,
    EditorJSComponent,
    BreadcrumbsComponent,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/vehicle-page.styl"
</style>
